import {
    CButton,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
  } from '@coreui/react';
  import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
  import styles from './Table.module.css';
import TableFooter from './TableFooter';
  
  
  
  const CouponsTable = ({ columns, data, pagination }) => {

    const navigate = useNavigate();

    const {setCouponsPageUrl} = useContext(AppContext);
  
    return (
        <>
          <CTable responsive hover>
            <CTableHead>
              <CTableRow>
                {columns.map((col, index) => (
                  <CTableHeaderCell key={index} scope="col">
                    {col.label}
                  </CTableHeaderCell>
                ))}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data?.map((el, index) => (
                <CTableRow key={index}>
                  <CTableDataCell>{el.id}</CTableDataCell>
                  <CTableDataCell>{el.code}</CTableDataCell>
                  <CTableDataCell>{el.couponeable_type == "company_subscription" ? "Suscripción" : "Chapita"}</CTableDataCell>
                  <CTableDataCell>{el.description}</CTableDataCell>
                  <CTableDataCell>{Math.round(el.discount * 100)}%</CTableDataCell>
                  <CTableDataCell>{el.uses || "-"}</CTableDataCell>
                  <CTableDataCell>{el.uses - el.used || "-"}</CTableDataCell>
                  <CTableDataCell>{el.expiration_date || "-"}</CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      className={styles.downloadQR}
                      onClick={() => {
                        navigate(`/dashboard/coupons/${el?.id}`);
                      }}
                    >
                      Editar
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
          <TableFooter range={pagination?.links} setPageUrl={setCouponsPageUrl} />
      </>
    );
  };
  
  export default CouponsTable;
  