import { CButton, CCol, CContainer, CForm, CFormInput, CFormSelect, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './Coupon.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';




const Coupon = () => {

    //Navigation
    let {couponId} = useParams();
    const navigate = useNavigate();

    //Context
    const { state, dispatcher, couponApiBody, getSelectableUsers, setCouponApiBody, getCoupon, addCoupon, editCoupon, deleteCoupon } = React.useContext(AppContext);

    const [selectedOption, setSelectedOption] = useState();
    const [typeOption, setTypeOption] = useState();
    const [displayableDiscount, setDisplayableDiscount] = useState(0);

    //Methods
    const handleInputChange = (e) =>{
        const {id, value} = e.target;
        setCouponApiBody({...couponApiBody, [id]:value})
    }
    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setCouponApiBody({...couponApiBody, user_id:option.value});
    }
    const handleTypeOption = (e) => {
        setTypeOption(e.target.value);
        setCouponApiBody({...couponApiBody, couponeable_type:e.target.value});
    }
    const handleDiscountChange = (e) => {
        setDisplayableDiscount(e.target.value);
        setCouponApiBody({...couponApiBody, discount:e.target.value/100});
    }
    const handleAction = (couponId) => {
        couponId ? 
        editCoupon(couponId, couponApiBody, setCouponApiBody, navigate) : 
        addCoupon(couponApiBody, setCouponApiBody, navigate);
    }

    useEffect(() => {
        setCouponApiBody({
            code:state?.coupon?.code,
            description:state?.coupon?.description,
            discount:displayableDiscount/100,
            user_id:state?.coupon?.user?.id,
            expiration_date:state?.coupon?.expiration_date,
            uses: state?.coupon?.uses,
            couponeable_type: state?.coupon?.couponeable_type
        })
        setTypeOption(state?.coupon?.couponeable_type || "badge")
        setDisplayableDiscount(Math.round(+state?.coupon?.discount*100));
        setSelectedOption({
            value:state?.coupon?.user?.id || null, 
            label:state?.selectableUsers?.find(user => user.value == state?.coupon?.user?.id)?.label
        })
    },[state?.coupon])

    useEffect(() => {
        getCoupon(couponId);
        getSelectableUsers();
        return () => {
            dispatcher({ type: 'set', coupon: {} });
        };
    },[couponId])



    return (
        <>
            <CustomBreadcrumbs parentTitle={'Cupones'} activeTitle={couponId ? "Editar cupón" : "Agregar cupón"} />
            <CContainer>
                <CRow>
                    <CCol xs={12} lg={6}>
                        <div className={styles.orderContainer}>
                            {/* Order header */}
                            <div className={styles.orderHeader}>
                                <h2 className='title'> 
                                    {couponId ? "Editar cupón" : "Agregar cupón"}
                                </h2>
                            </div>
                        </div>
                        <CForm>
                            <div className="mb-3 d-flex">
                                <div className='px-2 w-100'>
                                    <CFormInput
                                        value={couponApiBody?.code}
                                        type="text"
                                        id="code"
                                        label="Código del cupón"
                                        placeholder="Código"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className='px-2 w-100'>
                                    <CFormInput
                                        value={displayableDiscount}
                                        type="number"
                                        id="discount"
                                        label="Descuento"
                                        placeholder="Descuento"
                                        text="Debe ser un número del 1 al 95."
                                        onChange={(e) => handleDiscountChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='mb-4 d-flex'>
                                <div className='px-2 w-100'>
                                    <CFormInput
                                        value={couponApiBody?.uses}
                                        type="number"
                                        id="uses"
                                        label="Cantidad máxima de usos"
                                        placeholder="Cantidad de usos"
                                        text="Debe ser un número mayor o igual a 1."
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className='px-2 w-100'>
                                    <CFormInput
                                        value={couponApiBody?.expiration_date}
                                        type="date"
                                        id="expiration_date"
                                        label="Fecha de vencimiento"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='mb-4 px-2'>
                                <CFormSelect
                                    value={typeOption}
                                    id="couponable_type"
                                    label="Tipo de cupón"
                                    onChange={(e) => handleTypeOption(e)}
                                    options={[
                                        { label: 'Seleccionar...', value: '0'},
                                        { label: 'Chapita', value: 'badge' },
                                        { label: 'Suscripción', value: 'company_subscription'}
                                    ]}
                                />
                            </div>
                            <div className='mb-4 px-2'>
                                <CFormInput
                                    value={couponApiBody?.description}
                                    type="text"
                                    id="description"
                                    label="Descripción"
                                    placeholder="Descripción"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            {typeOption == "badge" &&
                                <div className='mb-4 px-2'>
                                <p className={styles.label}>Usuario</p>
                                <Select
                                    name="user_id" 
                                    options={state?.selectableUsers}
                                    value={selectedOption} 
                                    onChange={(option) => handleSelectOption(option)}
                                    isClearable
                                />
                                <p className={styles.small}>Opcional. Se debe completar si se quiere permitir que sólo un usuario específico pueda usar el cupón.</p>
                                </div>
                            }
                            <div className="d-grid gap-2 d-md-block">
                                <CButton 
                                    className={styles.button}
                                    onClick={() => handleAction(couponId)}
                                >
                                    {couponId ? "Guardar cambios" : "Agregar cupón"}
                                </CButton>
                                {couponId &&
                                    <CButton 
                                        onClick={() => deleteCoupon(couponId, setCouponApiBody, navigate)} 
                                        className={styles.buttonDelete}
                                    >
                                        Eliminar cupón
                                    </CButton>
                                }
                                {!couponId &&
                                    <CButton 
                                        onClick={() => {navigate(-1); dispatcher({ type: 'set', coupon: {} });}} 
                                        className={styles.buttonCancel}
                                    >
                                        Cancelar
                                    </CButton>
                                }
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default Coupon;
