/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { cilCart, cilCreditCard, cilDog, cilGift, cilList, cilPaw, cilQrCode, cilSettings, cilSpeedometer, cilTag, cilTags, cilUser, cilIndustry } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavItem, CNavTitle } from '@coreui/react';


const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
  },
  {
    component: CNavTitle,
    name: 'Usuarios y mascotas'
  },
  {
    component: CNavItem,
    name: 'Usuarios',
    to: '/dashboard/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Mascotas',
    to: '/dashboard/pets',
    icon: <CIcon icon={cilDog} customClassName="nav-icon" />
  },
  {
    component: CNavTitle,
    name: 'Monetización'
  },
  {
    component: CNavItem,
    name: 'Pedidos',
    to: '/dashboard/orders',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Productos',
    to: '/dashboard/settings/products',
    icon: <CIcon icon={cilTag} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Cupones',
    to: '/dashboard/coupons',
    icon: <CIcon icon={cilGift} customClassName="nav-icon" />
  },
  {
    component: CNavTitle,
    name: 'Lugares Pet Friendly'
  },
  {
    component: CNavItem,
    name: 'Empresas',
    to: '/dashboard/companies',
    icon: <CIcon icon={cilIndustry} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Sucursales',
    to: '/dashboard/branches',
    icon: <CIcon icon={cilPaw} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Categorías',
    to: '/dashboard/company-categories',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Suscripciones',
    to: '/dashboard/subscriptions',
    icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />
  },
  {
    component: CNavTitle,
    name: 'Configuración'
  },
  // {
  //   component: CNavItem,
  //   name: 'General',
  //   to: '/dashboard/settings',
  //   icon: <CIcon icon={cilSettings} customClassName="nav-icon" />
  // }
];

export default _nav;
