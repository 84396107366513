import { CContainer, CRow, CCol } from '@coreui/react';
import { Outlet } from 'react-router-dom';
// import logoHorizontal from '../../assets/images/logo-horizontal.svg';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
const DashboardOutlet = () => {
  return (
    <CContainer fluid>
      <Sidebar />
      {/* <Header /> */}

      <Outlet />
    </CContainer>
  );
};

export default DashboardOutlet;
