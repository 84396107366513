import { CButton, CCol, CContainer, CForm, CFormCheck, CFormInput, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './CompanyCategory.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cilCheck, cilCheckCircle, cilPencil, cilTrash, cilXCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';



const CompanyCategory = () => {

    //Context
    const { state, getCompanyCategory, addCompanyCategory, editCompanyCategory, deleteCompanyCategory, getCategoryServices, addCategoryService, editCategoryService, deleteCategoryService, dispatcher} = React.useContext(AppContext);

    let {companyCategoriesId} = useParams();

    //State
    const [apiBody, setApiBody] = useState({});
    const [edit, setEdit] = useState({active:false, content:"", id:""});
    const [showAddCategory, setShowAddCategory] = useState(false);

    const navigate = useNavigate();

    //Methods
    const handleInputChange = (e) =>{
        const {id, value} = e.target;
        setApiBody({...apiBody, [id]:value});
    }
    const handleEdit = (action, service) => {
        action === "edit" && setEdit({active:true, content:service.name, id:service.id})
        action === "submit" && (
            editCategoryService(
                service.id, 
                {name:edit.content, company_category_id: companyCategoriesId},
                setEdit({active:false, content:"", id:""})
            ))
    }
    const handleEditChange = (e) =>{
        const {value} = e.target;
        setEdit({...edit, content:value});
    }
    const handleDelete = (action, service) => {
        action === "cancel" && setEdit({active:false, content:"", id:""})
        action === "delete" && (
            deleteCategoryService(
                service.id,
                setEdit({id:service.id})
            ))
    }
    const handleAdd = () => {
        addCategoryService(
            {name:edit.content, company_category_id: companyCategoriesId},
            setShowAddCategory(false) && setEdit({active:false, content:"", id:""})
        )
    }

    useEffect(() => {
        getCategoryServices(companyCategoriesId);
    },[edit, showAddCategory])

    useEffect(() => {
        companyCategoriesId && getCompanyCategory(companyCategoriesId);
        return () => {
            dispatcher({ type: 'set', companyCategory: {} });
        };
    },[companyCategoriesId])

    useEffect(() => (
        setApiBody({
            name:state.companyCategory?.name, 
            description:state.companyCategory?.description
        })
    ),[state.companyCategory])


    return (
        <>
            <CustomBreadcrumbs parentTitle={'Veterinarias & lugares Pet Friendly'} activeTitle={companyCategoriesId ? "Editar categoría" : "Agregar categoría"} />
            <CContainer>
                <CRow>
                    <CCol xs={12} lg={6}>
                        <div className={styles.orderContainer}>
                            {/* Order header */}
                            <div className={styles.orderHeader}>
                                <h2 className='title'> 
                                    {companyCategoriesId ? "Editar categoría" : "Agregar categoría"}
                                </h2>
                            </div>
                        </div>
                        <CForm>
                            <div className='mb-3'>
                                <CFormInput
                                    value={apiBody?.name}
                                    type="text"
                                    id="name"
                                    label="Título"
                                    placeholder="Título"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className='mb-3'>
                                <CFormInput
                                    value={apiBody?.description}
                                    type="text"
                                    id="description"
                                    label="Descripción"
                                    placeholder="Descripción"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            {companyCategoriesId ?
                                <div className='mb-5'>
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label">Servicios</label>
                                        <p 
                                            className={styles.addButton}
                                            onClick={() => {
                                                setShowAddCategory(true);
                                            }}
                                        >
                                            Agregar +
                                        </p>
                                    </div>
                                    {state.categoryServices?.map((service,i) => (
                                        <div className={styles.serviceContainer} key={i}>
                                            {edit.active && service.id === edit.id ?
                                                <CFormInput
                                                    value={edit.content}
                                                    type="text"
                                                    id={service.name}
                                                    placeholder="Servicio"
                                                    onChange={(e) => handleEditChange(e)}
                                                /> :
                                                <label>
                                                    {service.name}
                                                </label>
                                            }
                                            <div className="d-flex">
                                                <CIcon
                                                    icon={(edit.active && service.id === edit.id) ? cilCheckCircle : cilPencil} 
                                                    className={styles.serviceIcon}
                                                    onClick={() => handleEdit(edit.active ? "submit" : "edit", service)}
                                                    size="lg"
                                                />
                                                <CIcon
                                                    icon={(edit.active && service.id === edit.id) ? cilXCircle : cilTrash} 
                                                    className={styles.serviceIcon}
                                                    size="lg"
                                                    onClick={() => handleDelete(edit.active ? "cancel" : "delete", service)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {showAddCategory &&
                                        <div className={styles.serviceContainer}>
                                            <CFormInput
                                                value={edit.content}
                                                type="text"
                                                id="servicio"
                                                placeholder="Servicio"
                                                onChange={(e) => handleEditChange(e)}
                                            />
                                            <div className="d-flex">
                                                <CIcon
                                                    icon={cilCheckCircle} 
                                                    className={styles.serviceIcon}
                                                    onClick={() => handleAdd()}
                                                    size="lg"
                                                />
                                                <CIcon
                                                    icon={cilXCircle} 
                                                    className={styles.serviceIcon}
                                                    size="lg"
                                                    onClick={() => setShowAddCategory(false)}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div> :
                                <div className='mb-5'>
                                    <label className="form-label">Servicios</label>
                                    <p>
                                       Para agregar servicios a esta categoría, primero guarda los cambios.
                                    </p>
                                </div>
                            }
                            <div className="d-grid gap-2 d-md-block ">
                                <CButton 
                                    className={styles.button}
                                    onClick={() => companyCategoriesId ? editCompanyCategory(companyCategoriesId, apiBody, setApiBody, navigate) : addCompanyCategory(apiBody, setApiBody, navigate)}
                                >
                                    {companyCategoriesId ? "Guardar cambios" : "Agregar categoría"}
                                </CButton>
                                {companyCategoriesId &&
                                    <CButton 
                                        onClick={() => deleteCompanyCategory(companyCategoriesId, setApiBody, navigate)} 
                                        className={styles.buttonDelete}
                                    >
                                        Eliminar categoría
                                    </CButton>
                                }
                                {!companyCategoriesId &&
                                    <CButton 
                                        onClick={() => navigate(-1)} 
                                        className={styles.buttonCancel}
                                    >
                                        Cancelar
                                    </CButton>
                                }
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default CompanyCategory;
