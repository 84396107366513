import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DashboardOutlet } from '../components';
import { BranchesScreen, CompaniesScreen, CompanyCategoriesScreen, CompanyCategoryScreen, CompanyScreen, CouponScreen, CouponsScreen, HomeScreen, ListPetsScreen, ListUsersScreen, LoginScreen, SettingsProductScreen, SettingsProductsScreen, SuscriptionScreen, SuscriptionsScreen} from '../views';
import QROrders from '../views/QROrders/QROrders';
import SingleOrder from '../views/SingleOrder/SingleOrder';
import { AuthContext } from '../context/AuthContext';
import React from 'react';
import axios from 'axios';

const CustomRoutes = () => {

  const { state, signOut } = React.useContext(AuthContext);

  /* -------------------------------------------------------------------------- */
  /*                              Axios interceptor                             */
  /* -------------------------------------------------------------------------- */
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        //let userToken = await SecureStore.getItemAsync('token');
        let userToken = localStorage.getItem('token');
        if (userToken !== 'null') {
          config.headers.Authorization = `Bearer ${userToken}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function axiosRetryInterceptor(err) {
      if (err.response.status === 401 || err.response.data.message === '401 Unauthorized') {
        signOut();
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  );

  return (
    <BrowserRouter>
      <Routes>
        {state.userToken !== null ? (
          <>
            <Route exact path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<DashboardOutlet />}>
              <Route exact path="/dashboard/" element={<HomeScreen />} />
              {/* Users & pets */}
              <Route exact path="/dashboard/users" element={<ListUsersScreen />} />
              <Route exact path="/dashboard/pets" element={<ListPetsScreen />} />
              {/* Orders */}
              <Route exact path="/dashboard/orders" element={<QROrders/>} />
              <Route path="/dashboard/orders/:orderId" element={<SingleOrder/>} />
              {/* Coupons */}
              <Route exact path="/dashboard/coupons" element={<CouponsScreen/>} />
              <Route exact path="/dashboard/coupons/add" element={<CouponScreen/>} />
              <Route exact path="/dashboard/coupons/:couponId" element={<CouponScreen/>} />
              {/* Products */}
              <Route exact path="/dashboard/settings/products" element={<SettingsProductsScreen/>} />
              <Route exact path="/dashboard/settings/products/:productId" element={<SettingsProductScreen/>} />
              <Route exact path="/dashboard/settings/products/add" element={<SettingsProductScreen/>} />
              {/* Companies */}
              <Route exact path="/dashboard/companies" element={<CompaniesScreen/>} />
              <Route exact path="/dashboard/branches" element={<BranchesScreen/>} />
              <Route exact path="/dashboard/companies/:companyId" element={<CompanyScreen/>} />
              {/* Categories */}
              <Route exact path="/dashboard/company-categories" element={<CompanyCategoriesScreen/>} />
              <Route exact path="/dashboard/company-categories/add" element={<CompanyCategoryScreen/>} />
              <Route exact path="/dashboard/company-categories/:companyCategoriesId" element={<CompanyCategoryScreen/>} />
              {/* Suscriptions */}
              <Route exact path="/dashboard/subscriptions" element={<SuscriptionsScreen/>} />
              <Route exact path="/dashboard/subscriptions/:suscriptionId" element={<SuscriptionScreen/>} />
              <Route exact path="/dashboard/subscriptions/add" element={<SuscriptionScreen/>} />
            </Route>
          </>
        ) : (
          <>
            <Route exact path="/" element={<LoginScreen />} />
            <Route exact path="/dashboard" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default CustomRoutes;
