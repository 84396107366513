import {
  CButton,
  CCol,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Table.module.css';
import { AppContext } from '../../context/AppContext';
import TableFooter from './TableFooter';




const Table = ({ columns, data, pagination }) => {

  const {setOrdersPageUrl} = useContext(AppContext);

  //Navigation
  const navigate = useNavigate();

  //Methods
  const handleAction = (id) => {
    
  }


  return (
    <>
      {/* <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por Nº de pedido"
          style={{ width: '100%' }}
        />
      </CCol>
      <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre o apellido"
          style={{ width: '100%' }}
        />
      </CCol> */}

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns.map((col, index) => (
                <CTableHeaderCell key={index} scope="col">
                  {col.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data?.map((order, index) => (
              <CTableRow key={index}>
                <CTableDataCell>#{order?.id}</CTableDataCell>
                <CTableDataCell>{order?.date}</CTableDataCell>
                <CTableDataCell>
                  <p className={[styles[`status-${order?.order_status?.id}`], styles.statusContainer].join(' ')}>{order?.order_status?.name}</p>
                </CTableDataCell>
                <CTableDataCell>{order?.fullname}</CTableDataCell>
                <CTableDataCell>{order?.shipping_code || "-"}</CTableDataCell>
                <CTableDataCell>
                  <CButton 
                    className={styles['downloadQR']} 
                    onClick={() => navigate(`/dashboard/orders/${order?.id}`)}
                  >
                    Ver pedido
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setOrdersPageUrl} />
    </>
  );
};

export default Table;
