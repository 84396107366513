import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, {useContext} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from './Table.module.css';
import { AppContext } from '../../context/AppContext';
import TableFooter from './TableFooter';




const Table = ({ columns, data, pagination }) => {

  const {setBranchesPageUrl} = useContext(AppContext);

  //Navigation
  const navigate = useNavigate();

  console.log(data)

  return (
    <>
      {/* <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por Nº de pedido"
          style={{ width: '100%' }}
        />
      </CCol>
      <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre o apellido"
          style={{ width: '100%' }}
        />
      </CCol> */}

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns.map((col, index) => (
                <CTableHeaderCell key={index} scope="col">
                  {col.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data?.map((branch, index) => (
              <CTableRow key={index}>
                <CTableDataCell>{branch?.id}</CTableDataCell>
                <CTableDataCell>
                  <Link to={`/dashboard/companies/${branch?.company?.id}`} style={{color:"black"}}>
                    {branch?.company?.company_name}
                  </Link>
                </CTableDataCell>
                <CTableDataCell>{branch?.name}</CTableDataCell>
                <CTableDataCell>{branch?.branch_status?.name}</CTableDataCell>
                <CTableDataCell>
                  {branch.company?.company_category?.name}
                </CTableDataCell>
                <CTableDataCell>{branch.email}</CTableDataCell>
                <CTableDataCell>{branch.phone || "-"}</CTableDataCell>
                <CTableDataCell>{branch.whatsapp || "-"}</CTableDataCell>
                <CTableDataCell>{branch.location.address}</CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setBranchesPageUrl} />
    </>
  );
};

export default Table;
