//Production
// export const API_URL = 'https://app.findpetapp.com/v2/';
// export const PUBLIC_URL = 'https://app.findpetapp.com/';
// export const FICHAS_URL = 'https://fichas.findpetapp.com/';
// export const CARNET_URL = 'https://app.findpetapp.com/v2/ruf/';

//Development
export const API_URL = 'https://dev.findpetapp.com/v2/';
export const PUBLIC_URL = 'https://dev.findpetapp.com/';
export const FICHAS_URL = 'https://fichas.findpetapp.com/';
export const CARNET_URL = 'https://dev.findpetapp.com/v2/ruf/';
