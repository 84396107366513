import { CContainer, CRow, CCol } from '@coreui/react';
import React, {useEffect, useContext} from 'react';
import { CardMetrics, CustomBreadcrumbs, UserTable } from '../../components';
import { AppContext } from '../../context/AppContext';
import user from '../../assets/images/cil-user.svg';


const ListUsers = () => {

  const { state, getUsers, usersPageUrl } = useContext(AppContext);
  const columns = [
    {
      key: 'id',
      label: '#',
      _props: { scope: 'col' }
    },
    {
      key: 'name',
      label: 'Nombre',
      _props: { scope: 'col' }
    },
    {
      key: 'email',
      label: 'Email',
      _props: { scope: 'col' }
    },
    {
      key: 'phone',
      label: 'Teléfono',
      _props: { scope: 'col' }
    },
    // {
    //   key: 'actions',
    //   label: 'Acciones',
    //   _props: { scope: 'col' }
    // }
  ];
  const users = state.users.map((user) => {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      phone: user.phone,
      is_active: user.is_active
    };
  });

  //Effects
  useEffect(() => {
    getUsers();
  }, [usersPageUrl]);


  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Usuarios y mascotas'} activeTitle="Usuarios" />
      <CContainer>
        <CRow className="justify-content-between">
          <CCol xs={12}>
            <h2 className='title'>Resumen</h2>
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={state.users.filter((u) => u.is_active).length}
              subtitle="Usuarios activos"
              cardStyle="primary"
              icon={user}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={state.users.filter((u) => !u.is_active).length}
              subtitle="Usuarios inactivos"
              cardStyle="secondary"
              icon={user}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={state.users.filter((u) => u.rol === 'admin').length}
              subtitle="Usuarios administradores"
              cardStyle="tertiary"
              icon={user}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12}>
            <h2 className='title mb-4'>Usuarios</h2>
          </CCol>
          <UserTable 
            columns={columns} 
            data={users} 
            pagination={state?.usersPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default ListUsers;
