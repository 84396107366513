import axios from 'axios';
import React, { useState } from 'react';
import { API_URL } from '../constants';
import { ErrorHandler } from '../utils/ErrorHandler';
import download from 'downloadjs'
const AppContext = React.createContext();
const { Provider } = AppContext;



const AppProvider = ({ children }) => {

  //States
  const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case 'set':
        return { ...state, ...rest };
      default:
        return state;
    }
  };
  
  const initialState = {
    sidebarShow: true,
    sidebarUnfoldable: true,
    pets: [],
    petsPagination:[],
    users: [],
    usersPagination:[],
    selectableUsers:[],
    stats: {},
    orders: [],
    ordersPagination:[],
    order:{},
    statuses:[],
    RUF:"",
    products:[],
    productsPagination:[],
    product:{},
    coupons:[],
    couponsPagination:[],
    coupon:{},
    companies:[],
    company:{},
    branches:[],
    companyCategories:[],
    companyCategory:{},
    categoryServices:[],
    categoryService:{}
  };
  const [state, dispatcher] = React.useReducer(changeState, initialState);
  const [orderApiBody, setOrderApiBody] = useState({
    badge_request_status_id:state?.order?.status_shipping?.id,
    tracking_code:state?.order?.tracking_code
  });
  const [productApiBody, setProductApiBody] = useState({
    name:"",
    price:""
  });
  const [subscriptionApiBody, setSubscriptionApiBody] = useState({
    name: "",
    description: "",
    price: "",
    payment_frequency: "",
    is_featured: "",
    branches_limit: "",
    pg_subscription_plan_id: ""
  });
  const [couponApiBody, setCouponApiBody] = useState({
    code: state?.coupon?.code,
    discount:state?.coupon?.discount,
    description: state?.coupon?.description,
    uses: state?.coupon?.uses,
    expiration_date: state?.coupon?.expiration_date,
    user_id:state?.coupon?.user_id,
    couponeable_type: "badge"
  });
  const [usersPageUrl, setUsersPageUrl] = useState(`${API_URL}admin/users`);
  const [petsPageUrl, setPetsPageUrl] = useState(`${API_URL}admin/pets`);
  const [ordersPageUrl, setOrdersPageUrl] = useState(`${API_URL}admin/badge-requests`);
  const [couponsPageUrl, setCouponsPageUrl] = useState(`${API_URL}admin/coupons`);
  const [companiesPageUrl, setCompaniesPageUrl] = useState(`${API_URL}admin/companies`);
  const [branchesPageUrl, setBranchesPageUrl] = useState(`${API_URL}admin/companies/branches`);
  const [companyCategoriesPageUrl, setCompanyCategoriesPageUrl] = useState(`${API_URL}admin/companies/categories`);
  const [productsPageUrl, setProductsPageUrl] = useState(`${API_URL}admin/badge-products `);
  const [suscriptionsPageUrl, setSuscriptionsPageUrl] = useState(`${API_URL}admin/companies/subscriptions/plans`);
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSucess] = useState(false);

  //Dashboard
  const getDashboardData = () => {
    axios
      .get(`${API_URL}admin/dashboard`)
      .then((res) => {
        dispatcher({ type: 'set', stats:res.data.stats});
      })
      .catch((err) => ErrorHandler(err));
  };

  //Users
  const getUsers = () => {
    axios
      .get(usersPageUrl)
      .then((res) => {
        dispatcher({ type: 'set', users: res.data.data });
        dispatcher({ type: 'set', usersPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getSelectableUsers = () => {
    axios
      .get(`${API_URL}admin/users/select-options`)
      .then((res) => {
        dispatcher({ type: 'set', selectableUsers: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };

  //Pets
  const getPets = () => {
    axios
      .get(petsPageUrl)
      .then((res) => {
        dispatcher({ type: 'set', pets: res.data.data });
        dispatcher({ type: 'set', petsPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const searchPets = (name, ruf) => {
    axios
      .get(petsPageUrl, {
        params:{
          name:name,
          pet_identification: ruf
        }
      })
      .then((res) => {
        dispatcher({ type: 'set', pets: res.data.data });
        dispatcher({ type: 'set', petsPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  }

  //Orders
  const getOrders = () => {
    axios
      .get(ordersPageUrl)
      .then((res) => {
        dispatcher({ type: 'set', orders: res.data.data });
        dispatcher({ type: 'set', ordersPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getOrder = (id) => {
    axios
      .get(`${API_URL}admin/badge-requests/${id}`)
      .then((res) => {
        dispatcher({ type: 'set', order: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getStatuses = () => {
    axios
      .get(`${API_URL}admin/badge-requests/statuses`)
      .then((res) => {
        dispatcher({ type: 'set', statuses: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getRUF = (petId, ownerId, orderId) => {
    axios
      .get(`${API_URL}admin/badge-requests/ruf/${petId}/${ownerId}`, {
        responseType: 'blob'
      })
      .then((res) => {
        const content = res.headers['content-type'];
        download(res.data,`Findpet RUF - Pedido Nº #${orderId}`,content);
        
      })
      .catch((err) => ErrorHandler(err));
  };
  const editOrder = (id) => {

    setLoading(true);

    axios
      .patch(`${API_URL}admin/badge-requests/${id}`, orderApiBody)
      .then((res) => {
        setLoading(false);
        setSubmitSucess(true);
        setOrderApiBody({});
        setTimeout(() => {
          setSubmitSucess(false);
        }, 6000);
      })
      .catch((err) => {
        ErrorHandler(err);
        setLoading(false);
      });
  }

  //Products
  const getProducts = () => {
    axios
      .get(productsPageUrl)
      .then((res) => {
        dispatcher({ type: 'set', products: res.data });
        dispatcher({ type: 'set', productsPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const addProduct = (body, setBody, navigate) => {
    axios
      .post(productsPageUrl, body)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/settings/products");
        }, 1000);
        setBody({});
      })
      .catch((err) => ErrorHandler(err));
  };
  const editProduct = (id, body, setBody, navigate) => {
    axios
      .put(`${API_URL}admin/badge-products/${id}`, body)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/settings/products");
        }, 1000);
        setBody({});
      })
      .catch((err) => {ErrorHandler(err);});
  };
  const deleteProduct = (id, setBody, navigate) => {
    axios
      .delete(`${API_URL}admin/badge-products/${id}`)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/settings/products");
        }, 1000);
        setBody({});
      })
      .catch((err) => ErrorHandler(err));
  };

  //Coupons
  const getCoupon = (id) => {
    axios
      .get(`${couponsPageUrl}/${id}`)
      .then((res) => {
        dispatcher({ type: 'set', coupon: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getCoupons = () => {
    axios
      .get(`${couponsPageUrl}`)
      .then((res) => {
        dispatcher({ type: 'set', coupons: res.data.data });
        dispatcher({ type: 'set', couponsPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const addCoupon = (body, setBody, navigate) => {
    axios
      .post(`${couponsPageUrl}`, body)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/coupons");
        }, 1000);
        setBody({});
      })
      .catch((err) => ErrorHandler(err));
  };
  const editCoupon = (id, body, setBody, navigate) => {
    
    axios
      .patch(`${couponsPageUrl}/${id}`, body)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/coupons");
        }, 1000);
        setBody({});
      })
      .catch((err) => {ErrorHandler(err);});
  };
  const deleteCoupon = (id, setBody, navigate) => {
    axios
      .delete(`${couponsPageUrl}/${id}`)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/coupons");
        }, 1000);
        setBody({});
      })
      .catch((err) => ErrorHandler(err));
  };

  //Companies
  const getCompany = (id) => {
    axios
      .get(`${companiesPageUrl}/${id}`)
      .then((res) => {
        dispatcher({ type: 'set', company: res.data.company });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getCompanies = () => {
    axios
      .get(`${companiesPageUrl}`)
      .then((res) => {
        dispatcher({ type: 'set', companies: res.data.data });
        dispatcher({ type: 'set', companiesPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getBranchesByCompanyId = (id) => {
    axios
      .get(`${companiesPageUrl}/${id}/branches`)
      .then((res) => {
        dispatcher({ type: 'set', branches: res.data.data});
      })
      .catch((err) => ErrorHandler(err));
  };
  const getBranches = () => {
    axios
      .get(`${companiesPageUrl}/branches`)
      .then((res) => {
        dispatcher({ type: 'set', branches: res.data.data});
        dispatcher({ type: 'set', branchesPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const disableCompany = (id, navigate) => {
    axios
      .delete(`${companiesPageUrl}/${id}`)
      .then((res)=>{
        navigate(0)
      })
      .catch((err) => ErrorHandler(err));
  }
  const enableCompany = (id) => {
    axios
      .post(`${companiesPageUrl}/${id}/enable`)
      .then((res) => {
        dispatcher({ type: 'set', company: res.data.company });
      })
      .catch((err) => ErrorHandler(err));
  }

  //Company Categories
  const getCompanyCategory = (id) => {
    axios
      .get(`${companyCategoriesPageUrl}/${id}`)
      .then((res) => {
        dispatcher({ type: 'set', companyCategory: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getCompanyCategories = () => {
    axios
      .get(`${companyCategoriesPageUrl}`)
      .then((res) => {
        dispatcher({ type: 'set', companyCategories: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const addCompanyCategory = (body, setBody, navigate) => {
    axios
      .post(`${companyCategoriesPageUrl}`, body)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/company-categories");
        }, 1000);
        setBody({});
      })
      .catch((err) => ErrorHandler(err));
  };
  const editCompanyCategory = (id, body, setBody, navigate) => {
    axios
      .patch(`${companyCategoriesPageUrl}/${id}`, body)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/company-categories");
        }, 1000);
        setBody({});
      })
      .catch((err) => {ErrorHandler(err);});
  };
  const deleteCompanyCategory = (id, setBody, navigate) => {
    axios
      .delete(`${companyCategoriesPageUrl}/${id}`)
      .then((res) => {
        setTimeout(() => {
          navigate("/dashboard/company-categories");
        }, 1000);
        setBody({});
      })
      .catch((err) => ErrorHandler(err));
  };

  //Category Services
  const getCategoryServices = (id) => {
    axios
      .get(`${companyCategoriesPageUrl}/${id}/services`)
      .then((res) => {
        dispatcher({ type: 'set', categoryServices: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const addCategoryService = (body, action) => {
    axios
      .post(`${companyCategoriesPageUrl}/services`, body)
      .then((res) => action)
      .catch((err) => ErrorHandler(err));
  };
  const editCategoryService = (id, body, action) => {
    axios
      .patch(`${companyCategoriesPageUrl}/services/${id}`, body)
      .then((res) => action)
      .catch((err) => {ErrorHandler(err);});
  };
  const deleteCategoryService = (id, action) => {
    axios
      .delete(`${companyCategoriesPageUrl}/services/${id}`)
      .then((res) => action)
      .catch((err) => ErrorHandler(err));
  };

  //Subscription
  const getSubscription = (id) => {
    axios
      .get(`${suscriptionsPageUrl}/${id}`)
      .then((res) => {
        dispatcher({ type: 'set', subscription: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getSubscriptions = () => {
    axios
      .get(`${suscriptionsPageUrl}`)
      .then((res) => {
        dispatcher({ type: 'set', subscriptions: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const addSubscription = (body, setBody, navigate) => {
    axios
      .post(`${suscriptionsPageUrl}`, body)
      .then((res) => {
        setBody({});
        setTimeout(() => {
          navigate('/dashboard/subscriptions')
        }, 1000)
      })
      .catch((err) => ErrorHandler(err));
  };
  const editSubscription = (id, body, setBody, navigate) => {
    axios
      .patch(`${suscriptionsPageUrl}/${id}`, body)
      .then((res) =>{
        setBody({});
        setTimeout(() => {
          navigate('/dashboard/subscriptions')
        }, 1000)
      })
      .catch((err) => {ErrorHandler(err);});
  };
  const deleteSubscription = (id, setBody, navigate) => {
    axios
      .delete(`${suscriptionsPageUrl}/${id}`)
      .then((res) =>{
        setBody({});
        setTimeout(() => {
          navigate('/dashboard/subscriptions')
        }, 1000)
      })
      .catch((err) => ErrorHandler(err));
  };


  return (
    <Provider
      value={{
        disableCompany,  enableCompany ,
        getDashboardData,
        getPets, searchPets,
        setPetsPageUrl, petsPageUrl,
        getUsers, getSelectableUsers,
        setUsersPageUrl, usersPageUrl,
        getOrders,
        setOrdersPageUrl, ordersPageUrl,
        getOrder,
        orderApiBody, setOrderApiBody,
        getStatuses,
        getRUF,
        getCompanyCategories, getCompanyCategory, companyCategoriesPageUrl, setCompanyCategoriesPageUrl,addCompanyCategory, editCompanyCategory,deleteCompanyCategory,
        getCategoryServices, editCategoryService, deleteCategoryService,addCategoryService,
        getSubscriptions, getSubscription, subscriptionApiBody, setSubscriptionApiBody,
        addSubscription, editSubscription, deleteSubscription,
        getCompanies, getCompany, companiesPageUrl, setCompaniesPageUrl,
        getBranches,getBranchesByCompanyId, branchesPageUrl, setBranchesPageUrl,
        getProducts, addProduct, editProduct, deleteProduct,
        productsPageUrl, setProductsPageUrl,
        productApiBody, setProductApiBody,
        getCoupon, getCoupons, addCoupon, editCoupon, deleteCoupon,
        couponsPageUrl, setCouponsPageUrl,
        couponApiBody, setCouponApiBody,
        loading, submitSuccess,
        editOrder,
        changeState,
        dispatcher,
        state
      }}>
      {children}
    </Provider>
  );
};
export { AppContext, AppProvider };
