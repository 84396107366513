import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { LoginScreen } from './views';
import { DashboardOutlet } from './components';
import { AuthProvider } from './context/AuthContext';
import { AppProvider } from './context/AppContext';
import CustomRoutes from './routes/routes';

function App() {
  return (
    <AuthProvider>
      <AppProvider>
        <CustomRoutes />
      </AppProvider>
    </AuthProvider>
  );
}

export default App;
