import { CCol, CContainer, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import styles from './SingleOrder.module.css';
import back from '../../assets/images/cil-arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import qr from '../../assets/images/cil-qr-code.svg';
import info from '../../assets/images/cil-description.svg';
import truck from '../../assets/images/cil-truck.svg';
import chevron from '../../assets/images/chevron-down.svg';
import spinner from '../../assets/images/spinner.gif';



const SingleOrder = () => {

    const { state, getOrder, getStatuses, getRUF, orderApiBody, setOrderApiBody, editOrder, loading, submitSuccess, dispatcher } = React.useContext(AppContext);

    //Navigation
    let { orderId } = useParams();
    const navigate = useNavigate();

    //States
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(state?.order?.status_shipping);
    const [inputValue, setInputValue] = useState();

    //Methods
    const handleTrackCodeChange = (e) => {
        const {id, value} = e.target;
        setOrderApiBody({...orderApiBody,[id]:value});
        setInputValue(value);
    }
    
    //Effect
    useEffect(() => {
        getOrder(orderId);
        return () => {
            dispatcher({ type: 'set', order: {} });
        };
    },[orderId])
    useEffect(() => {
        getStatuses();
        setOrderApiBody({
            badge_request_status_id: state?.order?.status_shipping?.id,
            tracking_code: state?.order?.tracking_code || ""
        });
        setInputValue(state?.order?.tracking_code);
        setSelectedStatus(state?.order?.status_shipping);
    },[state?.order])


    return (
        <div>
            <CustomBreadcrumbs parentTitle={'Identificadores QR'} activeTitle={`Pedido #${state?.order?.id}`} />
            <CContainer>
                <CRow className="justify-content-between">
                <CCol lg={12}>
                    <CRow className="justify-content-center">
                        <CCol xs={8}>
                            <div 
                                className={styles.backButtonContainer}
                                onClick={() => navigate(-1)}
                            >    
                                <img src={back} width="20" alt="back" />
                                <p className={styles.backButton}>Volver</p>
                            </div>
                        </CCol>
                        <CCol xs={8}>
                            <div className={styles.orderContainer}>
                                {/* Order header */}
                                <div className={styles.orderHeader}>
                                    <h2 className='title'>Pedido #{state?.order?.id}</h2>
                                    <div 
                                        className={styles.orderStatus}
                                        onClick={() => setShowDropdown(!showDropdown)}
                                    >
                                        <p className={styles[`status-${selectedStatus?.id}`]}>
                                            {selectedStatus?.name}
                                        </p>
                                        <img src={chevron} alt="chevron" />
                                        {showDropdown &&
                                            <div className={styles.orderStatusDropdown}>
                                                { 
                                                    state?.statuses?.map((status,i) => (
                                                        <p 
                                                            key={i}
                                                            onClick={() => {setShowDropdown(false); setSelectedStatus(status); setOrderApiBody({...orderApiBody, badge_request_status_id:status.id})}}
                                                        >
                                                            {status?.name}
                                                        </p>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>

                                {/* Order Body */}
                                <div className={styles.orderBodySection}>
                                    <div className={[styles['orderSectionIcon'], styles['primary']].join(' ')}>
                                        <img src={qr} alt="icon" />
                                    </div>
                                    <div className={styles.orderSectionContent}>
                                        <h3 className={styles.orderSubtitle}>Detalles del pedido</h3>
                                        <p><b>Nombre de la mascota: </b>{state?.order?.pet?.name}</p>
                                        <p><b>Tamaño: </b>{state?.order?.badge_size?.name}</p>
                                        <p><b>Cantidad: </b>{state?.order?.badge_product?.name}</p>
                                        <p><b>Precio: </b>${state?.order?.badge_product?.price}</p>
                                        <br />
                                        <a href={state?.order?.qr_code_url} className='blueBtn' target="_blank">Descargar QR</a>
                                        <a 
                                            className='blueBtn' 
                                            target="_blank" 
                                            onClick={() => getRUF(state?.order?.pet?.id, state?.order?.buyer?.id, orderId)}
                                            download
                                        >
                                            Descargar RUF
                                        </a>
                                    </div>
                                </div>
                                <div className={styles.orderBodySection}>
                                    <div className={[styles['orderSectionIcon'], styles['secondary']].join(' ')}>
                                        <img src={info} alt="icon" />
                                    </div>
                                    <div className={styles.orderSectionContent}>
                                        <h3 className={styles.orderSubtitle}>Datos de facturación</h3>
                                        <p><b>Nombre y apellido: </b>{state?.order?.full_name}</p>
                                        <p><b>CUIT/CUIL: </b>{state?.order?.identification_number}</p>
                                        <p><b>Teléfono: </b>{state?.order?.phone}</p>
                                        <p><b>Email: </b>{state?.order?.email}</p>
                                    </div>
                                </div>
                                <div className={styles.orderBodySection}>
                                    <div className={[styles['orderSectionIcon'], styles['tertiary']].join(' ')}>
                                        <img src={truck} alt="icon" />
                                    </div>
                                    <div className={styles.orderSectionContent}>
                                        <h3 className={styles.orderSubtitle}>Datos de envío</h3>
                                        <p><b>Provincia: </b>{state?.order?.badge_province?.name}</p>
                                        <p><b>Barrio/Localidad: </b>{state?.order?.location}</p>
                                        <p><b>CP: </b>{state?.order?.zip_code}</p>
                                        <p><b>Calle/Avenida: </b>{state?.order?.street_name}</p> 
                                        <p><b> Número: </b> {state?.order?.house_number}</p>
                                        <p><b>Piso/Dpto: </b>{state?.order?.clarification || "-"}</p>
                                        <br />
                                        <p><b>Código de seguimiento:</b></p>
                                        <input 
                                            id="tracking_code"
                                            type="text" 
                                            value={inputValue} 
                                            placeholder="xx-xxxxx-xxxx"
                                            onChange={(e) => handleTrackCodeChange(e)}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div
                                    style={{textAlign:"right"}}
                                >
                                    <a 
                                        className={submitSuccess ? 'successBtn' :'generalBtn'}
                                        onClick={() => editOrder(orderId)}
                                        disabled={loading && submitSuccess}
                                    >
                                        {
                                            loading ? 
                                            <img src={spinner} width="15" alt="loader"/> :
                                            submitSuccess ?
                                            "¡Cambios guardados con éxito!" :
                                            "Guardar cambios"
                                        }
                                    </a>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default SingleOrder;
